import React, { useState, useEffect } from "react";
import Header from "./header";
import Footer from "./footer";
import PopupForm from "./popupForm";

import "../css/sharan-style.css";
import "../css/style.css";
import "../css/animate.min.css";
import "../css/responsive.css";
import "../css/bootstrap.min.css";
import "../css/swiper-bundle.min.css";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";
import PhoneFlipBlueIcon from '../images/icons/phone-flip-solid-blue.svg';
import favIcon from '../images/favicon/favicon.ico';

const Layout = ({ children }) => {
	const [time, setTime] = useState(9000000000000);

	const query = useStaticQuery(graphql`
		{
			footerIcons: allFile(filter: { relativeDirectory: { eq: "footer" } }) {
				edges {
					node {
						id
						base
						childImageSharp {
							fixed(width: 30) {
								...GatsbyImageSharpFixed
							}
						}
					}
				}
			}
			footerMenuExplore: allWordpressWpApiMenusMenusItems(filter: { wordpress_id: { eq: 766 } }) {
				nodes {
					name
					items {
						classes
						title
						target
						url
						object_id
					}
				}
			}
			footerMenuInstitutions: allWordpressWpApiMenusMenusItems(filter: { wordpress_id: { eq: 764 } }) {
				nodes {
					name
					items {
						classes
						title
						target
						url
						object_id
					}
				}
			}
			footerMenuCountries: allWordpressWpApiMenusMenusItems(filter: { wordpress_id: { eq: 765 } }) {
				nodes {
					name
					items {
						classes
						title
						target
						url
						object_id
					}
				}
			}
			footerMenuInformation: allWordpressWpApiMenusMenusItems(filter: { wordpress_id: { eq: 903 } }) {
				nodes {
					name
					items {
						classes
						title
						target
						url
						object_id
					}
				}
			}
			footerMenuHybridPrograms: allWordpressWpApiMenusMenusItems(filter: { wordpress_id: { eq: 900 } }) {
				nodes {
					name
					items {
						classes
						title
						target
						url
						object_id
					}
				}
			}
			footerMenuOfferings: allWordpressWpApiMenusMenusItems(filter: { wordpress_id: { eq: 767 } }) {
				nodes {
					name
					items {
						classes
						title
						target
						url
						object_id
					}
				}
			}
			footerMenuAdmissions: allWordpressWpApiMenusMenusItems(filter: { wordpress_id: { eq: 764 } }) {
				nodes {
					name
					items {
						classes
						title
						target
						url
						object_id
					}
				}
			}
			footerOffices: allWordpressWpOurOffices {
				nodes {
					wordpress_id
					title
					content
					acf {
						google_map_link
					}
				}
			}

			footerContactInfo: allWordpressWpOurOffices(filter: { slug: { eq: "contact-info" } }) {
				nodes {
					wordpress_id
					title
					content
					acf {
						partnership_queries
						phone
						student_admissions
					}
				}
			}
			mainmenu: allWordpressWpApiMenusMenusItems(filter: { wordpress_id: { in: 769 } }) {
				edges {
					node {
						wordpress_id
						items {
							url
							title
							object_id
							wordpress_id
							target
							wordpress_children {
								url
								title
								target
								
							}
						}
					}
				}
			}

			buttonmenu: allWordpressWpApiMenusMenusItems(filter: { wordpress_id: { in: 768 } }) {
				edges {
					node {
						wordpress_id
						items {
							url
							title
							classes
							wordpress_id
							target
						}
					}
				}
			}

			# file(relativePath: {eq: "TWG-LOGO-01.png"}) {
			#   id
			#   childImageSharp {
			#       fixed {
			#       ...GatsbyImageSharpFixed
			#       }
			#   }
			# }
			allWordpressWpUniversities(sort: {fields: title}) {
				nodes {
				  id
				  slug
				  title
				  acf {
					country
					   website_url
          university_detail_page
				  }
				}
			  }
			allWordpressWpBlog(filter: {acf: {footer_blog_title: {ne: null}}}) {
				edges {
				  node {
					id
					slug
					title
					acf {
					  footer_blog_title
					  footer_blog_category
					}
				  }
				}
			}
			allWordpressWpMedia(filter: { wordpress_id: { in: 4128 } }) {
				edges {
					node {
						source_url
						wordpress_id
					}
				}
			}
		}
	`);



	useEffect(() => {
		setTime(9000000000000);

	}, []);

	const handlePopupWindow = () => {
		setTime(Math.random());
	};



	return (
		<div style={{ fontFamily: "'Poppins', sans-serif;"}}>
			<Helmet>
				<link rel="icon" href={favIcon} />
			</Helmet>
			<Header 
			logo={query.allWordpressWpMedia.edges[0].node.source_url} 
			menuitem={query.mainmenu.edges[0].node.items} 
			rightMenu={query.buttonmenu.edges[0].node.items} />
			<PopupForm time={time} />
	
			{children}
			<div className="col-12 footer-buttons-mobile">
				<a  href="tel:080-45681763" onClick={() => localStorage.setItem('isPopupShown', 'Never')} className="btn fixed-enquire-button speak-to-an-expert-btn" ><img src={PhoneFlipBlueIcon} alt="Icon" className="bottom-right newicon" />Call us</a>
				
				<a  href="https://www.instagram.com/theworldgrad/" onClick={() => localStorage.setItem('isPopupShown', 'Never')} className="btn fixed-enquire-button whatapp-chat-btn" ><img src="https://assets.theworldgrad.com/gatsby-assets/xtra/instagram.png" alt="Follow Us Instagram" className="left-icon" />Follow Us</a>

				
				
			</div>
			<Footer 
			iconsArr={query.footerIcons} 
			exploreMenu={query.footerMenuExplore.nodes[0]} 
			institutetionsMenu={query.footerMenuInstitutions.nodes[0]} 
			countriesMenu={query.footerMenuCountries.nodes[0]} 
			informationMenu={query.footerMenuInformation.nodes[0]} 
			hybridProgramsMenu={query.footerMenuHybridPrograms.nodes[0]} 
			listUniversities={query.allWordpressWpUniversities.nodes} 
			footerlistblog={query.allWordpressWpBlog.edges} 
			offeringsMenu={query.footerMenuOfferings.nodes[0]} 
			admissionsMenu={query.footerMenuAdmissions.nodes[0]}
			officeList={query.footerOffices.nodes}
			contactInfo={query.footerContactInfo.nodes[0]} />
		</div>
	);
};

export default Layout;
